import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import { createApp } from "vue";
import App from "./App.vue";
import "./index.scss";

import CountTo from "vue3-count-to";

import router from "./router";
createApp(App).use(router).use(ElementPlus).use(CountTo).mount("#app");
