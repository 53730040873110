import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/IndexView.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/Home/IndexView.vue"), //首页
      },
      {
        path: "/UnitedKingdom",
        name: "UnitedKingdom",
        component: () => import("@/views/UnitedKingdom/IndexView.vue"), //英国
      },
      {
        path: "/UnitedStates",
        name: "UnitedStates",
        component: () => import("@/views/UnitedStates/IndexView.vue"), //美国
      },
      {
        path: "/Australia",
        name: "Australia",
        component: () => import("@/views/Australia/IndexView.vue"), //澳洲
      },
      {
        path: "/Singapore",
        name: "Singapore",
        component: () => import("@/views/Singapore/IndexView.vue"), //新加坡
      },
      {
        path: "/HKMO",
        name: "HKMO",
        component: () => import("@/views/HKMO/IndexView.vue"), //香港澳门
      },
      {
        path: "/Canada",
        name: "Canada",
        component: () => import("@/views/Canada/IndexView.vue"), //加拿大
      },
      {
        path: "/Europe",
        name: "Europe",
        component: () => import("@/views/Europe/IndexView.vue"), //欧洲
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
