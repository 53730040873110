<template>
  <router-view />
</template>
<script lang="ts" setup>
import { onMounted } from "vue";
const isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};
onMounted(() => {
  if (isMobile()) {
    window.location.href = "http://m.manbosi.com";
  } 
});
</script>
<style lang="scss">
@use "./app.scss";
</style>
